import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Container, ListGroup } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <main id="content-home">
      <div className="content-overlay">
        <div className="home-content container">
          <h1>Sentosa Restaurant</h1>
          <h2>2063 Rt 88 Brick, NJ</h2>
          <Link to="/menu" type="button" className="btn btn-outline-light">
            MENU
          </Link>
        </div>
      </div>
    </main>
  </Layout>
)

export default IndexPage
